import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './TableOfContents.module.css';

const TableOfContents = ({ items }) => {
  const list = items.map((item, i) => {
    if (i === 0) {
      return (
        <div className={styles.tocLinkContainer} key={i}>
          {i + 1}.{' '}
          <Link to={item.link} className={styles.tocLink}>
            {item.text}
          </Link>
        </div>
      );
    } else {
      return (
        <div className={styles.tocLinkContainer} key={i}>
          {' '}|{' '}{i + 1}.{' '}
          <Link to={item.link} className={styles.tocLink}>
            {item.text}
          </Link>
        </div>
      );
    }
  });

  return (
    <div className={styles.toc}>
      <div className={styles.tocContainer}>{list}</div>
    </div>
  );
};

TableOfContents.propTypes = {
  items: PropTypes.array,
};

TableOfContents.defaultProps = {
  articles: [],
};

export default TableOfContents;
