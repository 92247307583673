import React from 'react';
import { Helmet } from 'react-helmet';

import Layout from '../components/Layout/Layout';
import Section from '../components/Section/Section';
import Example from '../components/Example/Example';
import TableOfContents from '../components/TableOfContents/TableOfContents';

import i326_1 from '../images/getting-started/326_1.png';
import i326_2 from '../images/getting-started/326_2.png';
import i326_3 from '../images/getting-started/326_3.png';
import CLA_1 from '../images/getting-started/CLA-1.png';
import CLA_2 from '../images/getting-started/CLA-2.png';

const Participation: React.FC<PageProps> = () => (
  <Layout>
    <Helmet>
      <title>Getting Started - IEEE SA OPEN</title>
    </Helmet>
    <TableOfContents
      items={[
        {
          text: 'Table of Contents',
          link: '/getting-started',
        },
        {
          text: 'Creating Your IEEE SA OPEN Single Sign On',
          link: '#sso',
        },
        {
          text: 'How to A Sign Contributor License Agreement',
          link: '#signcla',
        },
      ]}
    />
    <Section type={'light'}>
      <a className={'anchor'} id={'sso'} />
      <h1>Creating your IEEE SA OPEN Single Sign On</h1>

      <p>To sign in for the first time:</p>

      <ul>
        <li>
          If you are an IEEE member, log in to your IEEE account and then visit{' '}
          <a href={'https://opensource.ieee.org'}>
            https://opensource.ieee.org
          </a>{' '}
          and use your existing IEEE credentials to log in.
        </li>
        <li>
          If you are not an IEEE member, create a free account by visiting the{' '}
          <a href="https://www.ieee.org/profile/public/createwebaccount/showCreateAccount.html?url=https%3A%2F%2Fwww.ieee.org%2F%2F" target='_blank'>Create an IEEE Account page</a>. 
          Fill in the appropriate information (making sure to preserve these
          credentials for later use). When you have succeeded in creating an
          IEEE account, you will be returned to the main IEEE page. 
          </li>
          
          <li>
          Return to the IEEE main page indicates that your account has been created. You will need to return to{' '} 
          <a href="https://opensource.ieee.org"target='_blank'>https://opensource.ieee.org</a> and use your new login. You will now be able to access the IEEE SA OPEN site shown below.
        </li>
      </ul>
      <Example>
        <img alt={'create ieee sa open single sign on 1'} src={i326_1} />

        <p>
          Click in the inbox (highlighted in green in the above image) and you
          will be sent to the sign-in page (shown below). Use your IEEE login
          information to sign in.
        </p>

        <img alt={'create ieee sa open single sign on 2'} src={i326_2} />

        <p>
          The first time you log in, you will be asked to agree to the IEEE SA
          OPEN Terms of Use. Please scroll to the bottom of this page and click
          the green Accept terms button.
        </p>

        <img alt={'create ieee sa open single sign on 3'} src={i326_3} />

        <p>
          After you log in, you will be taken to the main page of IEEE SA OPEN.
          It runs on the popular GitLab CE platform. These same credentials will
          also allow you to log in the IEEE Mattermost chat server.
        </p>
      </Example>
      <a className={'anchor'} id={'signcla'} />
      <h3> How to Sign a Contributor License Agreement</h3>
      <Example>
        <p>
          When you sign on to the IEEE SA OPEN platform, you are joining a
          community of contributors. To make sure that your contributions to our
          community are licensed appropriately, please sign a Contributor
          License Agreement. Our community shares our work through the  <a href={'https://opensource.ieee.org/community/cla/apache'}> Apache
          2.0 license </a>
          </p>

        <img alt={'CLA'} src={CLA_1} />

        <p>
          There are three versions of each Contributing License Agreement.
          Please choose the one that is appropriate to your situation. If you
          are signing on behalf of a company, please complete the entity CLA. If
          you are contributing as an individual, please choose the individual
          CLA. If you are adding something to the public domain as a
          representative of a government, please complete the option with the
          public domain declaration.
        </p>

        <img alt={'CLA'} src={CLA_2} />

        <p>
          The first page of the CLA document provides instructions for how to
          choose a CLA document, how to fill it out, and how to submit it.
          Please email the signed CLA to{' '}
          <a href="mailto:oscontrib@ieee.org?subject=CLA%20%20from%20IEEE%20SA%20Website%20">
            oscontrib@ieee.org
          </a>
          .
        </p>

        <p>
          Once the CLA is signed and the submission process is completed, a CLA
          number will be provided to the representative or individual that
          submitted it.
        </p>
      </Example>
    </Section>
  </Layout>
);

export default Participation;
