import React from 'react';
import styles from './Example.module.css';
import PropTypes from 'prop-types';

const Example = ({ children }) => {
  return <div className={styles.example}>{children}</div>;
};

Example.propTypes = {
  children: PropTypes.node,
};

export default Example;
